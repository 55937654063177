<template>
  <b-row align-h="center" v-if="dataLoading" class="mt-2">
    <b-spinner
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Projects</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Project Name"
              invalid-feedback="Project name is required."
            >
              <b-form-input
                ref="dep_name"
                v-model="depObj.name"
                placeholder="Enter project name"
                @focusout="CheckName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="addDep()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="depList"
          :fields="depFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="Edit(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="Delete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebarVendor"
      bg-variant="white"
      v-model="visibilityVendor"
      sidebar-class="sidebar-lg"
      right
      width="500"
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Vendors</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBarVendor()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col md="10">
            <b-form-group
              label="Vendor Name"
              invalid-feedback="Vendor name is required."
            >
              <b-form-input
                ref="vendor_name"
                v-model="vendorObj.name"
                placeholder="Enter vendor name"
                @focusout="CheckVendorName()"
              >
              </b-form-input>
              <b-form-input
                ref="vendor_mobile"
                v-model="vendorObj.mobile_number"
                placeholder="Enter mobile no."
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="addVendor()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <feather-icon v-else :icon="accIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          show-empty
          :items="vendorList"
          :fields="vendorFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="EditVendor(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="DeleteVendor(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Voucher Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12" v-for="(item, ind) in vtSaveData" :key="ind">
            <b-form-group
              :label="item.voucherType"
              invalid-feedback="Account is required."
            >
              <v-select
                :ref="'account' + ind"
                :filterBy="dropFilter"
                v-model="item.accountID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accList"
                :reduce="(opt) => opt.id"
                label="title"
                :clearable="true"
                placeholder="Select account"
              />
              <!-- @input="testing(item)" -->
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button
              variant="success"
              @click="saveVtSettings()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <h3 class="mb-1">Journal Entry</h3>
    <b-card class="">
      <b-row>
        <b-col md="2">
          <!-- label="Voucher Type" -->
          <b-form-group invalid-feedback="Voucher Type is required." ref="vt">
            <div class="d-flex justify-content-between pb-50">
              <label class="bv-no-focus-ring col-form-label p-0"
                >Voucher Type</label
              >
              <feather-icon
                icon="SettingsIcon"
                size="18"
                class="cursor-pointer"
                v-b-tooltip.hover.top
                title="Settings"
                @click="openVtSettings()"
              />
            </div>
            <v-select
              v-model="saveObj.voucherType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              :reduce="(opt) => opt.text"
              label="text"
              :clearable="false"
              ref="vt"
              placeholder="Select type"
              @input="setVT()"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              :config="config"
              ref="date"
              v-model="saveObj.date"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cheque Title"
            invalid-feedback="Cheque Title required."
            ref="chTitle"
          >
            <b-form-input
              ref="chTitle"
              placeholder="Enter cheque title"
              v-model="saveObj.chequeTitle"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" v-if="false">
          <b-form-group
            label="Voucher Number"
            invalid-feedback="Voucher Number required."
            ref="vNo"
          >
            <b-form-input
              ref="vNo"
              type="number"
              placeholder="Enter Voucher No"
              :v-model="1"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" v-if="multiDep && multiDep.valueBool">
          <b-form-group invalid-feedback="Project is required." ref="dep">
            <div class="d-flex">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Project</label
              >
              <feather-icon
                class="cursor-pointer ml-50"
                icon="EditIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Manage Projects"
                @click="openDep()"
              />
            </div>
            <v-select
              v-model="saveObj.departmentID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="depList"
              :reduce="(opt) => opt.id"
              label="name"
              :clearable="false"
              ref="dep"
              placeholder="Select project"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" v-if="multiDep && multiDep.valueBool">
          <b-form-group invalid-feedback="Vendor is required." ref="vendor">
            <div class="d-flex">
              <label class="bv-no-focus-ring col-form-label pt-0">Vendor</label>
              <feather-icon
                class="cursor-pointer ml-50"
                icon="EditIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Manage Vendors"
                @click="openVendor()"
              />
            </div>
            <v-select
              v-model="saveObj.vendorID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="vendorList"
              :reduce="(opt) => opt.id"
              label="name"
              :clearable="false"
              ref="vendor"
              placeholder="Select Vendor"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col md="10">
          <b-form-group
            label="Remarks"
            invalid-feedback="Remarks required."
            ref="remarks"
          >
            <b-form-input
              ref="remarks"
              placeholder="Enter remarks"
              v-model="saveObj.remarks"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group invalid-feedback="Supplier required." ref="supp">
            <div class="d-flex justify-content-between">
              <label class="bv-no-focus-ring col-form-label pt-0"
                >Supplier</label
              >
              <feather-icon
                class="cursor-pointer"
                icon="EditIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Manage Suppliers"
                @click="openSupp()"
              />
            </div>
            <v-select
              v-model="saveObj.supplier"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="supplierList"
              :reduce="(opt) => opt.id"
              label="name"
              :clearable="false"
              ref="supp"
              placeholder="Select supplier"
            />
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-card>
    <b-card class="mb-0">
      <b-row v-for="(item, ind) in myObj" :key="ind" class="mb-1 mb-md-0">
        <!-- style="display: flex; align-items: center" -->
        <b-col md="2" class="pr-1 pr-md-0">
          <b-form-group
            label="Account"
            invalid-feedback="account is required."
            ref="acc"
          >
            <v-select
              :filterBy="dropFilter"
              v-model="item.accountID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="accList"
              :reduce="(opt) => opt.id"
              label="title"
              :clearable="false"
              ref="acc"
              placeholder="Select account"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="pr-1 pr-md-0">
          <b-form-group
            label="Debit"
            invalid-feedback="Debit is required."
            ref="debit"
          >
            <b-form-input
              :id="'debit' + ind"
              type="number"
              ref="debit"
              placeholder=""
              v-model="item.debit"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="pr-1 pr-md-0">
          <b-form-group
            label="Credit"
            invalid-feedback="Credit is required."
            ref="credit"
          >
            <b-form-input
              type="number"
              placeholder=""
              ref="credit"
              v-model="item.credit"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" class="pr-1 pr-md-0">
          <b-form-group
            label="Cheque No."
            invalid-feedback="Cheque No. is required."
            ref="chq"
          >
            <b-form-input
              placeholder="Enter Cheque no"
              ref="chq"
              v-model.trim="item.chequeNo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" class="pr-1 pr-md-0">
          <b-form-group
            label="Note"
            invalid-feedback="Note is required."
            ref="note"
          >
            <b-form-input
              placeholder="Enter note"
              ref="note"
              v-model="item.description"
            />
          </b-form-group>
        </b-col>
        <b-col md="1" class="mt-md-2">
          <b-button
            v-if="ind == myObj.length - 1"
            @click="AddValue(item, ind + 1)"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button
            v-if="myObj.length > 1"
            @click="removeValue(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div class="card-body">
      <b-row>
        <b-col md="2" class=""></b-col>
        <b-col md="2" class="pr-0">
          <h5 class="font-weight-bolder">Rs. {{ totalDebit }}</h5>
          <!-- <b-form-group label="Total Debit">
            <b-form-input
              type="number"
              placeholder=""
              disabled
              v-model="totalDebit"
            />
          </b-form-group> -->
        </b-col>
        <b-col md="2" class="pr-0">
          <h5 class="font-weight-bolder">Rs. {{ totalCredit }}</h5>
          <!-- <b-form-group label="Total Credit">
            <b-form-input
              type="number"
              placeholder=""
              disabled
              v-model="totalCredit"
            />
          </b-form-group> -->
        </b-col>
      </b-row>
    </div>

    <h3 class="mb-1">Attachments</h3>
    <b-card class="">
      <div class="d-flex flex-wrap">
        <div
          class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
          style="width: 110px; height: 96px"
          @click="!mydocloading && $refs.psamplefile.click()"
        >
          <b-spinner
            v-if="mydocloading"
            small
            label="Loading..."
            variant="primary"
          />
          <feather-icon v-else icon="PlusIcon" size="34" class="text-primary" />
        </div>
        <div
          class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-1 mb-1"
          style="width: 110px; height: 96px; position: relative"
          v-for="(file, index) in attachList"
          :key="index"
          @click="openFile(file)"
        >
          <b-avatar
            size="20"
            variant="primary"
            style="position: absolute; top: -5px; right: -5px"
          >
            <feather-icon icon="XIcon" @click.stop="deleteDocument(index)" />
          </b-avatar>
          <feather-icon
            :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
            size="30"
            class="text-primary"
          />
        </div>

        <input
          type="file"
          id="psamplefile"
          hidden
          ref="psamplefile"
          multiple
          @change="PsampleAdd()"
        />
      </div>
    </b-card>
    <b-row align-h="center" class="">
      <b-col md="4">
        <b-button
          class=""
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="Add()"
          :disabled="request"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <span v-else> Save </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-1">
      <b-col md="4">
        <b-button
          class=""
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="Add('print')"
          :disabled="request"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <feather-icon v-if="!request" icon="PrinterIcon" />
          <span v-if="!request"> Save & Print </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row align-h="center" class="mt-1" v-if="$route.params.id == 0">
      <b-col md="4">
        <b-button
          class=""
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          @click="Add(true)"
          :disabled="request"
          block
        >
          <b-spinner v-if="request" small type="grow" />
          <span v-else>Request For Approval</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BAvatar,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  // mounted() {
  //   window.addEventListener("keydown", this.handleEvent);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("keydown", this.handleEvent);
  // },
  async created() {
    let right = this.$store.state.rights["journal"];

    if (!right) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.$route.params.id = parseInt(this.$route.params.id);
      if (this.$route.params.id > 0 && right.edit) {
        this.saveObj = this.$route.params.data.entry;
        this.myObj = this.$route.params.data.details;
        this.LoadAccounts();
        this.loadDeps();
        this.loadVtSettings();
        this.loadSetting();
        this.loadVendors();
      } else if (this.$route.params.id == 0 && right.add) {
        this.LoadAccounts();
        this.loadDeps();
        this.loadVtSettings();
        this.loadSetting();
        this.loadVendors();
      } else {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      }
    }
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
    totalCredit() {
      let total = this.myObj.reduce(
        (sum, el) => (el.credit ? sum + parseInt(el.credit) : sum + 0),
        0
      );
      return total;
    },
    totalDebit() {
      let total = this.myObj.reduce(
        (sum, el) => (el.debit ? sum + parseInt(el.debit) : sum + 0),
        0
      );
      return total;
    },
  },
  data() {
    return {
      mydocloading: false,
      attachList: [],
      vendorObj: {
        id: 0,
        name: "",
        mobile_number: "",
        campusID: this.$store.state.userData.cId,
      },
      check: 1,
      request: false,
      visibility2: false,

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      visibilityVendor: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      vendorFields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      typeOptions: [
        { text: "CPV", value: "cpv" },
        { text: "BPV", value: "bpv" },
        { text: "CRV", value: "crv" },
        { text: "BRV", value: "brv" },
        { text: "JV", value: "jv" },
      ],
      accList: [],
      depList: [],
      vendorList: [],
      supplierList: [],
      myObj: [
        {
          id: 0,
          journalEntryID: parseInt(this.$route.params.id),
          accountID: 0,
          debit: 0,
          credit: 0,
          chequeNo: "",
          description: "",
          campusID: this.$store.state.userData.cId,
        },
      ],
      saveObj: {
        id: 0,
        voucherNum: 0,
        voucherType: this.$store.state.vType,
        date: new Date().toJSON(),
        description: "",
        status: "active",
        departmentID: 1,
        vendorID: 0,
        userID: this.$store.state.userData.userID,
        campusID: this.$store.state.userData.cId,
      },
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      depFields: [
        { label: "Name", key: "name" },
        { key: "actions", label: "actions" },
      ],
      saving: false,
      accIcon: "",
      vtData: [],
      vtSaveData: [],
      autoEntry: null,
      multiDep: null,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },

  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg|mp4)$/i.test(url);
    },
    PsampleAdd() {
      if (this.$refs.psamplefile.files.length !== 0) {
        this.mydocloading = true;
        var formdata = new FormData();
        for (var i = 0; i < this.$refs.psamplefile.files.length; i++) {
          formdata.append("file", this.$refs.psamplefile.files[i]);
        }

        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.attachList.push(el.path);
            });
            // this.myObj.attachments = this.attachList.join(",");
            this.mydocloading = false;
          })
          .catch((error) => {
            this.mydocloading = false;
            console.log(error);
          });
      }
    },
    deleteDocument(index) {
      this.attachList.splice(index, 1);
      // this.myObj.attachments = this.attachList.join(",");
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    handleEvent(event) {
      // console.log(event);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          this.Add();
        }
      }
    },
    setVT() {
      this.$store.commit("setVType", this.saveObj.voucherType);
    },

    async loadSetting() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.multiDep = res.find((el) => el.key == "accounts_multi_department");
      // console.log(this.multiDep);
    },

    async loadVtSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "VoucherAccounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.vtData = await this.get(obj);
      // console.log(this.vtData);
    },
    openVtSettings() {
      this.visibility2 = true;
      if (this.vtData.length == 0) {
        this.vtSaveData = [
          {
            id: 0,
            voucherType: "CPV",
            accountID: 0,
            campusID: this.$store.state.userData.cId,
          },
          {
            id: 0,
            voucherType: "BPV",
            accountID: 0,
            campusID: this.$store.state.userData.cId,
          },
          {
            id: 0,
            voucherType: "CRV",
            accountID: 0,
            campusID: this.$store.state.userData.cId,
          },
          {
            id: 0,
            voucherType: "BRV",
            accountID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
      } else this.vtSaveData = [...this.vtData];
    },
    setAutoAccount() {
      let obj = this.vtData.find(
        (el) => el.voucherType == this.saveObj.voucherType
      );
      // console.log(obj);
      if (
        obj &&
        obj.accountID != 0 &&
        obj.accountID != null &&
        obj.accountID != ""
      ) {
        this.autoEntry = {
          id: 0,
          journalEntryID: parseInt(this.$route.params.id),
          accountID: obj.accountID,
          debit: 0,
          credit: 0,
          chequeNo: "",
          description: "",
          campusID: this.$store.state.userData.cId,
        };

        if (
          this.saveObj.voucherType == "CPV" ||
          this.saveObj.voucherType == "BPV"
        ) {
          this.autoEntry.credit = this.totalDebit - this.totalCredit;
        } else if (
          this.saveObj.voucherType == "CRV" ||
          this.saveObj.voucherType == "BRV"
        ) {
          this.autoEntry.debit = this.totalCredit - this.totalDebit;
        }
        // console.log(this.autoEntry);
      } else this.autoEntry = null;
    },

    async saveVtSettings() {
      this.saving = true;
      this.vtSaveData.forEach((el) => {
        if (el.accountID == null || el.accountID == "") el.accountID = 0;
      });
      // console.log(this.vtSaveData);
      var status = await this.post({
        url:
          this.$store.state.domain +
          "VoucherAccounts/SaveData?db=" +
          this.$store.state.userData.db,
        body: this.vtSaveData,
        message: "Setting saved successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.saving = false;
      if (status) {
        this.loadVtSettings();
        this.visibility2 = false;
      }
    },

    openSupp() {
      this.$router.push("/manage-suppliers");
    },

    openDep() {
      this.accIcon = "PlusIcon";
      this.visibility = true;
      this.depObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["dep_name"];
      elem.state = undefined;
    },
    openVendor() {
      this.accIcon = "PlusIcon";
      this.visibilityVendor = true;
      this.vendorObj = {
        id: 0,
        name: "",
        mobile_number: "",
        campusID: this.$store.state.userData.cId,
      };
      var elem = this.$refs["vendor_name"];
      elem.state = undefined;
    },
    CheckName() {
      var elem = this.$refs["dep_name"];
      if (this.depObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckVendorName() {
      var elem = this.$refs["vendor_name"];
      if (this.vendorObj.name.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async addDep() {
      if (this.CheckName() == true) {
        this.saving = true;
        // console.log(this.depObj);
        if (this.depObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "AccountsDepartment?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "AccountsDepartment/" +
              this.depObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Department updated successfully.",
            context: this,
            body: this.depObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.loadDeps();
          var elem = this.$refs["dep_name"];
          elem.state = undefined;
          this.accIcon = "PlusIcon";
          this.depObj = {
            id: 0,
            name: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.saving = false;
      }
    },
    async Edit(item) {
      this.depObj = { ...item };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["dep_name"];
      elem.state = undefined;
      // console.log(this.depObj);
    },

    async Delete(id) {
      // console.log(id);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "AccountsDepartment/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Department deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadDeps();
      }
    },
    async addVendor() {
      if (this.CheckVendorName() == true) {
        this.saving = true;
        // console.log(this.depObj);
        if (this.vendorObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Vendors?db=" +
              this.$store.state.userData.db,
            body: this.vendorObj,
            message: "Vendor added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Vendors/" +
              this.vendorObj.id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Vendor updated successfully.",
            context: this,
            body: this.vendorObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.loadVendors();
          var elem = this.$refs["vendor_name"];
          elem.state = undefined;
          this.accIcon = "PlusIcon";
          this.vendorObj = {
            id: 0,
            name: "",
            mobile_number: "",
            campusID: this.$store.state.userData.cId,
          };
        }
        this.saving = false;
      }
    },
    async EditVendor(item) {
      //console.log(item);
      //alert(item.name);

      this.accIcon = "Edit2Icon";
      var elem = this.$refs["vendor_name"];
      elem.state = undefined;
      this.vendorObj.id = item.id;
      this.vendorObj.name = item.name;
      this.vendorObj.mobile_number = item.mobile_number;
      this.vendorObj.campusID = this.$store.state.userData.cId;
      console.log(this.vendorObj);
      // console.log(this.depObj);
    },
    async DeleteVendor(id) {
      // console.log(id);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Vendors/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Vendor deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadVendors();
      }
    },
    dropFilter(opt, label, search) {
      // console.log(opt, label, search);
      return (
        opt.title.toLowerCase().match(search.toLowerCase()) ||
        opt.code.toLowerCase().match(search.toLowerCase())
      );
    },
    AddValue(item, ind) {
      if (
        item.accountID != 0 &&
        // item.chequeNo != "" &&
        item.debit !== "" &&
        item.credit !== ""
      ) {
        this.myObj.push({
          id: 0,
          journalEntryID: parseInt(this.$route.params.id),
          accountID: 0,
          debit: 0,
          credit: 0,
          chequeNo: "",
          description: "",
          campusID: this.$store.state.userData.cId,
        });
        // console.log(this.myObj);
        setTimeout(() => {
          let elem = document.getElementById("debit" + ind);
          elem.focus();
          elem.select();
        }, 100);
      }
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "JournalEntry/DeleteEntry?cID=" +
            this.$store.state.userData.cId +
            "&db=" +
            this.$store.state.userData.db +
            "&detailID=" +
            item.id,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      }
    },

    CheckValues() {
      let state = true;
      this.myObj.forEach((el) => {
        el.debit = parseInt(el.debit);
        el.credit = parseInt(el.credit);
        if (
          el.accountID == 0 ||
          isNaN(el.credit) ||
          isNaN(el.debit)
          // || el.chequeNo == ""
        ) {
          state = false;
        }
      });
      return state;
    },
    CheckVType() {
      var elem = this.$refs["vt"];
      if (this.saveObj.voucherType.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckVNum() {
      var re = /^[0-9]+$/;
      var elem = this.$refs["vNo"];
      let x = parseInt(this.saveObj.voucherNum);
      if (!re.test(this.saveObj.voucherNum) || isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDep() {
      var elem = this.$refs["dep"];
      if (this.saveObj.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async Add(val) {
      this.CheckVType();
      //this.CheckVNum();
      // this.CheckDep();
      if (
        this.CheckVType() == false ||
        // this.CheckDep() == false ||
        this.CheckValues() == false
      ) {
        this.check = 1;
        this.$bvToast.toast("Please enter the details correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.$route.params.id == 0 && this.saveObj.voucherType != "JV") {
          this.setAutoAccount();
          if (this.autoEntry != null) {
            this.myObj.push(this.autoEntry);
          }
        }
        // this.myObj.forEach((element) => {
        //   element.journalEntryID = 0;
        // });
        let mybody = {
          entry: this.saveObj,
          details: this.myObj,
        };
        if (this.totalDebit !== this.totalCredit) {
          this.check = 1;
          this.$bvToast.toast("Debit and Credit must be equal!", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.saveObj.voucherNum = parseInt(this.saveObj.voucherNum);
          // console.log("obj:", this.saveObj, this.myObj);
          // console.log(this.totalDebit, this.totalCredit);
          if (val === true) this.saveObj.status = "pending";
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "JournalEntry/Save?db=" +
              this.$store.state.userData.db,
            body: mybody,
            message: "Journal saved successfully.",
            context: this,
            token: this.$store.state.userData.token,
            savingMarks: true,
          });
          this.request = false;
          this.autoEntry = null;
          if (status) {
            if (val == "print") {
              var url =
                `https://${this.reportDomain}.myskool.app/Account/PrintEntryVoucher?entryID=` +
                status.entry.id +
                "&dbb=" +
                this.$store.state.userData.db +
                "&cID=" +
                this.$store.state.userData.cId;

              window.open(url, "_blank");
            }
            this.$router.go(-1);
          }
          this.check = 1;
        }
      }
    },

    hideSideBar() {
      this.visibility = false;
    },
    hideSideBarVendor() {
      this.visibilityVendor = false;
    },

    async LoadAccounts() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accList = await this.get(obj);
      // console.log("acc", this.accList);
      this.dataLoading = false;
    },
    async loadDeps() {
      var obj = {
        url:
          this.$store.state.domain +
          "AccountsDepartment?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.depList = await this.get(obj);
      // console.log("dep", this.depList);
    },
    async loadVendors() {
      var obj = {
        url:
          this.$store.state.domain +
          "Vendors?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      var vData = await this.get(obj);

      this.vendorList.push({
        id: 0,
        name: "None",
        mobile_number: "",
        campusID: this.$store.state.userData.cId,
      });

      vData.forEach((element) => {
        this.vendorList.push({
          id: element.id,
          name: element.name,
          mobile_number: element.mobile_number,
          campusID: element.campusID,
        });
      });
      // console.log("dep", this.depList);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.border-dashed {
  border: 1px solid var(--primary);
  border-style: dashed;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
